window.onload=function(){

    var btnOpen=botmanChatWidget.widget.base.getElementsByTagName("div")[1];

    function wrap(el, wrapper) {
        var st=["position","top","right","width","height","border-radius"];
        for (var n=0;n<st.length;n++) wrapper.style[st[n]]=el.style[st[n]];
        // wrapper.setAttribute("href","javascript:botmanChatWidget.whisper('hi');ifr=document.getElementById('chatBotManFrame');ifr.focus();");
        // wrapper.onfocus=function(){this.style.outline="solid 20px #f00";};
        // wrapper.onblur=function(){this.style.outline="solid 0 #f00";};

        el.style.top=0;
        el.style.right=0;
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }
    wrap(btnOpen, document.createElement('a'));
}
