require('./scripts');
import Vue from 'vue'
window.Vue = Vue;
require('./bootstrap');
require('./botman');







/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
/*

import {TinkerComponent} from 'botman-tinker';
Vue.component('botman-tinker', TinkerComponent);

const app = new Vue({
    el: '#app'
});
*/


